import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import QACommon from '../Views/QA/QACommon';

const Common = lazy(() => import('../Views/Common'));
const App = lazy(() => import('../Views/App'));
const About = lazy(() => import('../Views/About/About'));
const Contact = lazy(() => import('../Views/Contact/Contact'));
const Property = lazy(() => import('../Views/Property/Property'));
const QASuburb = lazy(() => import('../Views/QA/QA.suburb'));
const QAPropertyType = lazy(() => import('../Views/QA/QA.propertyType'));
const QALayout = lazy(() => import('../Views/QA/QA.layout'));
const QARent = lazy(() => import('../Views/QA/QA.rent'));
const QATimeframe = lazy(() => import('../Views/QA/QA.timeframe'));
const QAContact = lazy(() => import('../Views/QA/QA.contact'));
const QAReport = lazy(() => import('../Views/QA/QA.report'));
const QAManagement = lazy(() => import('../Views/QA/QA.management'));
const QAInsurance = lazy(() => import('../Views/QA/QA.insurance'));
const QAIndex = lazy(() => import('../Views/QA/QAIndex'));
const Team = lazy(() => import('../Views/Team/Team'));

const AllSeasonRouter = () => {
  return (
    <Suspense>
      <Router>
        <Routes>
          <Route path="/" element={<Common />}>
            <Route index element={<App />} />
            <Route path="home" element={<App />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="property" element={<Property fallback="Loading..." />} />
            <Route path="team" element={<Team />} />
          </Route>

          <Route path="/QA" element={<QACommon />}>
            <Route index element={<QAIndex />} />
            {/*Want routes*/}
            <Route path="Want" element={<QASuburb />} />
            <Route path="Want/suburb" element={<QASuburb />} />
            <Route path="Want/propertyType" element={<QAPropertyType />} />
            <Route path="Want/layout" element={<QALayout />} />
            <Route path="Want/rent" element={<QARent />} />
            <Route path="Want/timeframe" element={<QATimeframe />} />
            <Route path="Want/contact" element={<QAContact />} />

            {/*Have routes*/}
            <Route path="Have" element={<QASuburb />} />
            <Route path="Have/suburb" element={<QASuburb />} />
            <Route path="Have/management" element={<QAManagement />} />
            <Route path="Have/propertyType" element={<QAPropertyType />} />
            <Route path="Have/layout" element={<QALayout />} />
            <Route path="Have/rent" element={<QARent />} />
            <Route path="Have/insurance" element={<QAInsurance />} />
            <Route path="Have/contact" element={<QAContact />} />

            {/*Report routes*/}
            <Route path="report" element={<QAReport />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
};

export default AllSeasonRouter;
