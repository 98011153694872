import { createSlice } from '@reduxjs/toolkit';
import { sessionStorageAPI } from '../../utils';

const initialState = {
  value: 0,
  qaData: {
    mode: null,
    suburb: null,
    propertyType: null,
    layout: {
      beds: null,
      baths: null,
      carSpaces: null,
    },
    address: null,
    position: {
      lat: 0,
      lng: 0,
    },
    managementRate: null,
    insurance: null,
    rent: null,
    timeFrame: null,
    contact: {
      phone: null,
      email: null,
    },
  },
};

const qaSlice = createSlice({
  name: 'qa-progress',
  initialState,
  reducers: {
    updateProgress: (state, action) => {
      state.value = action.payload;
    },
    updateQAData: (state, action) => {
      state.qaData = { ...state.qaData, ...action.payload };

      sessionStorageAPI.set('qaData', state.qaData);
    },
  },
});

export const { updateProgress, updateQAData } = qaSlice.actions;

export const qaReducer = qaSlice.reducer;
