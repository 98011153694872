import React from 'react';
import PropTypes from 'prop-types';

import '../index.css';

const ProgressBar = ({ value }) => {
  if (value > 0) {
    return (
      <div className="progress-bar">
        <div className="progress-bar-fill" style={{ width: `${value}%` }}></div>
      </div>
    );
  }
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ProgressBar;
