import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import '../../Content/bootstrap.css';
import '../../Content/style.css';
import ProgressBar from './components/ProgressBar';

const QACommon = () => {
  const { currentPage } = useSelector(state => state.common);
  const { value } = useSelector(state => state.qa);

  useEffect(() => {
    const docEl = document.documentElement;
    const dpr = 1;
    const scale = 1 / dpr;
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';

    docEl.dataset.dpr = dpr.toString();

    const metaEl = document.createElement('meta');
    metaEl.name = 'viewport';
    metaEl.content = `initial-scale=${scale},maximum-scale=${scale}, minimum-scale=${scale}`;
    docEl.firstElementChild.appendChild(metaEl);

    const recalc = function () {
      let width = docEl.clientWidth;
      if (width / dpr > 750) {
        width = 750 * dpr;
      }
      // 乘以100，px : rem = 100 : 1
      docEl.style.fontSize = 100 * (width / 750) + 'px';
    };

    recalc();
    window.addEventListener(resizeEvt, recalc, false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{currentPage} - all seasons.</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui"
        />
      </Helmet>

      <nav className="navbar navbar-default main-box" role="navigation" id="top-nav">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="/top_logo_ha.png" height="64" alt="logo" />
            </a>
          </div>
        </div>
      </nav>

      <ProgressBar value={value} />

      <Outlet />
    </>
  );
};
export default QACommon;
