import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { commonReducer } from './slices/commonSlice';
import { qaReducer } from './slices/qaSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  qa: qaReducer,
});

export function setupStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}
