import emailjs from '@emailjs/browser';

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const validatePhone = phone => {
  const re = /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/;
  return re.test(String(phone));
};

export const validateAddress = addr => {
  const re = /[\w ,]{3,}(QLD|NSW|VIC|ACT|TAS|NT|WA|SA)[, ]*(\d{4})*[, ]*(AUSTRALIA|AU)$/;
  return re.test(String(addr).toUpperCase());
};

export const sessionStorageAPI = {
  get: function (key) {
    const value = sessionStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  },

  set: function (key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  },

  remove: function (key) {
    sessionStorage.removeItem(key);
  },

  clear: function () {
    sessionStorage.clear();
  },
};

export const sendEmail = content => {
  return emailjs.send(
    'service_s6osh9s',
    'template_76tufxu',
    {
      from_name: 'allseason.com.au',
      message: content,
    },
    'GbBgOOKGwl647n4Zr'
  );
};
